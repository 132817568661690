import React from "react";
import Navigation from "../navigation/navigation";
import FooterPage from "../navigation/footer";

import StoryImage from '../img/story-img.png';
import MissionImage from '../img/mission-image.png';
// import ManagementImage from '../img/management.png';


function AboutPage() {
   

    return (
        <>
            <div className="container-fluid position-relative p-0 ">

                <Navigation />

                <div className="container-fluid py-5 about-header mb-5">
                    <div className="container my-5 py-5">
                        <div className="row align-items-center g-5">
                            <div className=" col-xl-7 text-center text-lg-start p-1 py-md-3">
                                <h1 className="text-white animate__animated animate__slideInLeft animate__slower 1s p-1 p-md-5">
                                    About Us
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* About Start */}
            <div className="container">
                <div className="row g-5 align-items-center">
                    
                    <div className="col-12 offset-lg-2 col-lg-8 text-dark">
                        <h3 className="mb-3 animate__animated animate__fadeInRight animate__slower 1s">
                            Empowering Realtors with Seamless Call Management
                        </h3>

                        <p className="mb-4 animate__animated animate__fadeInLeft animate__slower 1s">
                            At ManageMyCalls, we started with a simple idea: to help busy professionals stay connected without being tied to their phones. As a team of innovative thinkers and problem-solvers, we realized how many opportunities were slipping through the cracks due to missed calls. Realtors, small business owners, and service providers often couldn't respond to every inquiry while focusing on their work.
                        </p>
                        <p className="mb-4 animate__animated animate__fadeInLeft animate__slower 1s">
                            That’s when the concept of ManageMyCalls was born—a 24/7 call management solution that responds to missed calls instantly via text, captures lead information, and helps professionals follow up when they’re ready.
                        </p>

                        <p className="mb-4 animate__animated animate__fadeInLeft animate__slower 1s">
                            Our unique connection to U.S. college students came from a collaboration with some of the brightest minds in the country. We partnered with college students to develop a system that not only works seamlessly but also addresses the needs of busy professionals. This project has provided a valuable learning opportunity for students, giving them hands-on experience in developing real-world solutions and building a product that serves professionals nationwide.
                        </p>
                        <p className="mb-4 animate__animated animate__fadeInLeft animate__slower 1s">
                            With their input, we’ve refined our service to make sure it’s efficient, affordable, and customizable. Today, ManageMyCalls continues to grow as a leader in text-based call management, helping businesses save time and never miss an opportunity to connect with potential clients.
                            We believe in empowering the next generation of entrepreneurs and professionals, and our collaboration with college students is just one way we’re making a difference.
                        </p>

                    </div>

                    {/* <div className="col-12 col-lg-5 text-dark">
                    <img
                            className="img-fluid w-100 animate__animated animate__zoomIn animate__slower 1s"
                            src={ManagementImage} alt=''
                        />
                    </div> */}

                    
                </div>
            </div>
            {/* About End  */}


            {/* Vacation Section Start */}
            <div className="container">
                <div className="row align-items-center green-bg">
                    <div className="col-lg-6 order-2 order-sm-1 px-0">
                        <img
                            className="img-fluid w-100 animate__animated animate__zoomIn animate__slower 1s"
                            src={MissionImage} alt=''
                        />
                    </div>
                    <div className="col-lg-6 order-1 order-sm-2 px-3 px-md-5 py-5 text-white">
                        <h4 className="animate__animated animate__fadeInDown animate__slower 1s">Mission</h4>
                        <p className="mb-4 animate__animated animate__fadeInDown animate__slower 1s">
                            We empower real estate professionals by offering a seamless text-based call management solution, ensuring no lead goes unnoticed. Our system captures and organizes every interaction, giving you the freedom to focus on growing your business.
                        </p>

                        <h4 className="mb-4 animate__animated animate__fadeInDown animate__slower 1s">Vision</h4>
                        <p className="mb-4 animate__animated animate__fadeInDown animate__slower 1s">
                            We envision a real estate industry where every realtor can concentrate on selling homes, knowing they have a reliable partner ensuring no opportunity slips through the cracks.
                        </p>

                    </div>


                </div>
            </div>
            {/* Vacation Section End  */}

          

            {/* Future call Start */}
            <div className="container my-3">
                <div className="row align-items-center ">
                   
                    <div className="col-lg-6  px-3 px-md-5 text-dark">
                        <h4 className="animate__animated animate__fadeInDown animate__slower 1s mb-4">The Future of Call Management with ManageMyCalls</h4>
                        <p className="mb-4 animate__animated animate__fadeInDown animate__slower 1s">
                        At ManageMyCalls, we provide a cutting-edge, 24/7 text-based call management solution designed to ensure no lead is ever missed. Our service goes beyond traditional voicemail or missed call notifications, leveraging AI to streamline communication and automate follow-ups, making it easier for professionals to focus on what matters most—building relationships and growing their business.
                        </p>


                    </div>

                    <div className="col-lg-6 0">
                        <img
                            className="img-fluid w-100 animate__animated animate__zoomIn animate__slower 1s"
                            src={StoryImage} alt=''
                        />
                    </div>


                </div>
            </div>
            {/* Future call End  */}

            {/* Lets go section Start */}
            {/* <div className="container-fluid process-bg py-5 text-white">
                <div className="container">

                    <div className="row g-4 align-items-center">
                        <div className="col-lg-8 col-sm-6 wow fadeInUp pe-3 pe-sm-5" data-wow-delay="0.1s">
                            <h2
                                className="section-title text-start ff-secondary fw-normal animate__animated animate__fadeInRight animate__slower 1s mb-4">
                                Manage Calls
                            </h2>
                            <p className="">
                                Effortlessly manage and track your business calls with our seamless, customizable call-handling solutions.
                            </p>
                        </div>

                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                        <Link to="/" 
                                    className="btn main-btn text-white py-sm-2 px-sm-5 me-3 fw-bold animate__animated animate__fadeInLeft animate__slower 1s">
                                        Start with Sign Up
                                </Link>
                        </div>
                    </div>
                </div>
            </div> */}
            {/*  lets go section End */}


            
            {/* Blog Section Start */}
            {/* <div className="container-xxl pt-5 pb-3">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h2 className="section-title ff-secondary text-center  fw-normal mb-4">Customer Says</h2>
                    </div>
                    <div className="row g-4 text-dark">
                        <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="blog-item text-center rounded overflow-hidden">
                                <div className="overflow-hidden m-4">
                                    <img className="img-fluid" src={blogImg1} alt="" />
                                </div>
                                <h6 className="mb-0">Neque porro quisquam est</h6>
                                <small>Ea animi incidunt aut consequuntur facilis id omnis consequuntur</small>
                                <div className="d-flex justify-content-center mt-3"> </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="blog-item text-center rounded overflow-hidden">
                                <div className="overflow-hidden m-4">
                                    <img className="img-fluid" src={blogImg2} alt="" />
                                </div>
                                <h6 className="mb-0">Neque porro quisquam est</h6>
                                <small>Ea animi incidunt aut consequuntur facilis id omnis consequuntur</small>
                                <div className="d-flex justify-content-center mt-3"> </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div> */}
            {/* Blog Secction End */}

            <FooterPage />

        </>
    )
}

export default AboutPage;