import React, { useState } from "react";
import Navigation from "../navigation/navigation";
import FooterPage from "../navigation/footer";
import { Link } from "react-router-dom";

import AboutImage from '../img/24-7-calls.jpg';
import RealtimeImage from '../img/realtime-management.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faFileEdit, faUser  } from "@fortawesome/free-solid-svg-icons";

function HomePage() {
  

    const [activeKey, setActiveKey] = useState('0');

    const toggleAccordion = (key) => {
        setActiveKey(activeKey === key ? null : key); // Toggles between open and closed
    };

    const [activeKey2, setActiveKey2] = useState('0');

    const toggleAccordion2 = (key) => {
        setActiveKey2(activeKey === key ? null : key); // Toggles between open and closed
    };

  

    return (
        <>
            <div className="container-fluid position-relative p-0 ">

                <Navigation />
                <Link to='/contact'>
                <div className="container-fluid py-5 home-header mb-5">
                    <div className="container my-5 py-5">
                        
                        <div className="row align-items-center g-5">
                            <div className=" col-xl-7 text-center text-lg-start mt-0 mt-md-5">
                                
                                <p className="text-white animate__animated animate__slideInLeft animate__slower 1s mb-0 mb-md-5 p-0 p-md-5">
                                {/* ManageMyCalls is a 24/7, text-based call management solution designed specifically for busy professionals, such as real estate agents. It ensures that no potential lead is missed when someone calls and you're unavailable.  */}
                                </p>
                                {/* <Link to="/contact" 
                                    className="btn main-btn text-white py-sm-3 px-sm-5 me-3 fw-bold animate__animated animate__fadeInLeft animate__slower 1s">
                                        Start with Sign Up
                                </Link> */}
                                
                            </div>
                            <div className="col-xl-5 ">

                            </div>
                        </div>
                        
                    </div>
                </div>
                </Link>
            </div>

            {/* About Start */}
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 order-2 order-sm-1">
                        <img
                            className="img-fluid rounded w-100 animate__animated animate__zoomIn animate__slower 1s"
                            src={AboutImage} alt='24/7 call management'
                        />

                    </div>
                    <div className="col-lg-6 text-dark order-1 order-sm-2">
                        <h4 className="section-title ff-secondary text-start fw-normal animate__animated animate__fadeInRight animate__slower 1s">About Us</h4>
                        <h3 className="mb-4 animate__animated animate__fadeInRight animate__slower 1s">ManageMyCalls </h3>
                        <p className="mb-4 animate__animated animate__fadeInDown animate__slower 1s">
                            it is a 24/7, text-based call management solution designed specifically for busy professionals, such as real estate agents. It ensures that no potential lead is missed when someone calls and you're unavailable. The system automatically sends text messages to missed callers, captures key information, and provides you with real-time notifications. By offering a seamless and efficient way to respond to calls without needing full-time staff, ManageMyCalls helps businesses stay connected with leads and clients, all for a simple monthly fee of $150.
                        </p>

                    </div>
                </div>
            </div>
            {/* About End  */}


            {/* Vacation Section Start */}
            <div className="container my-3 my-md-5">
                <div className="row g-5 align-items-center">

                    <div className="col-lg-6 text-dark">
                        <h4 className="section-title ff-secondary text-start fw-normal animate__animated animate__fadeInLeft animate__slower 1s">How It Works</h4>
                        <h3 className="mb-3 animate__animated animate__fadeInLeft animate__slower 1s">Simple and Seamless:</h3>
                        <ol>
                            <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                <b>Client Calls: </b>When you're unavailable, a potential client calls you.
                            </li>
                            <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                <b>Automatic Response: </b>Our system immediately sends a personalized text message to the caller.
                            </li>
                            <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 3s">
                                <b>Lead Capture: </b>The text gathers essential information from the caller, ensuring you don't miss any details.                            </li>
                            <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 3s">
                                <b>Real-Time Notifications: </b>All captured details are sent directly to you for quick follow-up.
                            </li>
                        </ol>

                        <p className="mb-4 animate__animated animate__fadeInLeft animate__slower 3s">
                            Whether you're on the go or with a client, ManageMyCalls ensures you're always connected.
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <img
                            className="img-fluid rounded w-100 animate__animated animate__zoomIn animate__slower 1s"
                            src={RealtimeImage} alt='Realtime call management'
                        />

                    </div>
                </div>
            </div>
            {/* Vacation Section End  */}

            {/* Why ManageMyCalls section Start */}
            <div className="container py-3 py-lg-5">
                <div className="row g-4 text-dark align-items-center">
                    <div className="col-md-6" >
                        <h3 className="mb-2d-block d-sm-none animate__animated animate__fadeInUp animate__slower 1s text-center">Why ManageMyCalls?</h3>
                        <div className="service-item text-center text-sm-start pt-3 my-3 ">
                            <div className="p-4">
                                <i className="fa fa-3x fa-user-tie text-primary mb-4"></i>
                                <h5>Never Miss a Lead</h5>
                                <p>ManageMyCalls ensures every missed call is followed up with a text response, so you never lose a potential client, even when you're busy.</p>
                            </div>
                        </div>

                        <div className="service-item text-center text-sm-start pt-3 my-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-utensils text-primary mb-4"></i>
                                <h5>24/7 Availability</h5>
                                <p>With round-the-clock coverage, ManageMyCalls captures leads at any time, providing a professional response even outside of business hours.</p>
                            </div>
                        </div>

                        <div className="service-item text-center text-sm-start pt-3 my-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-cart-plus text-primary mb-4"></i>
                                <h5>Cost-Effective Solution</h5>
                                <p>For just $150/month, you get unlimited call management, saving on the cost of hiring full-time reception staff.</p>
                            </div>
                        </div>

                    </div>

                    {/* <div className="col-lg-4 d-flex flex-column align-items-center order-3 order-lg-2 " >
                        <h3 className="mb-4 d-none d-sm-block animate__animated animate__fadeInUp animate__slower 1s text-center">Why ManageMyCalls?</h3>
                        <img
                            className="img-fluid  w-75 animate__animated animate__zoomIn animate__slower 1s"
                            src={GarbageCan} alt="Gabage can"
                        />
                    </div> */}

                    <div className="col-md-6  " >
                        <div className="service-item text-center text-sm-start pt-3 my-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-headset text-primary mb-4"></i>
                                <h5>Real-Time Notifications</h5>
                                <p>Receive instant alerts with detailed lead information, allowing you to follow up quickly and efficiently.</p>
                            </div>
                        </div>
                        <div className="service-item text-center text-sm-start pt-3my-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-cart-plus text-primary mb-4"></i>
                                <h5> Customizable Text Responses</h5>
                                <p>Tailor messages to fit your brand's voice and ensure a personalized experience for every caller.</p>
                            </div>
                        </div>
                        <div className="service-item text-center text-sm-start pt-3 my-3">
                            <div className="p-4">
                                <i className="fa fa-3x fa-cart-plus text-primary mb-4 "></i>
                                <h5>Easy CRM Integration</h5>
                                <p>Easily integrate with popular CRM systems to streamline lead tracking and ensure no opportunity falls through the cracks.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* Why ManageMyCalls section End */}


            {/* Simple Process Start */}
            <div className="container-fluid process-bg py-5 text-white">
                <div className="container">
                    <div className="row g-3 align-items-center justify-content-center py-3">
                        <h4 className="text-white ff-secondary text-center mb-3 mb-md-4 w-50 fw-normal animate__animated animate__fadeInRight animate__slower 1s ">
                            Simple Process to GO
                        </h4>
                        
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <FontAwesomeIcon icon={faUser} className="fa-3x mb-4" />
                                    <h5>Sign Up and Set Preferences</h5>
                                    <ul>
                                        <li>Create an account on our platform in minutes.</li>
                                        <li>Set your call-handling preferences, such as call forwarding, voicemail settings, and availability times.</li>
                                        <li>Choose whether you'd like a live representative to handle your calls or automated responses.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <FontAwesomeIcon icon={faFileEdit} className="fa-3x mb-4" />
                                    <h5>Receive and Manage Calls</h5>
                                    <ul>
                                        <li>Our system automatically routes incoming calls according to your preferences.</li>
                                        <li>You can choose to accept the call, forward it to another number, or let our system handle it with pre-set responses or voicemails.</li>
                                        <li>View detailed call logs and recordings via your dashboard in real-time.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <FontAwesomeIcon icon={faThumbsUp} className="fa-3x mb-4" />
                                    <h5>Track and Optimize</h5>
                                    <ul>
                                        <li>Access detailed analytics and reports to see who’s calling, at what times, and call outcomes.</li>
                                        <li>Adjust your call preferences anytime to improve efficiency.</li>
                                        <li>Use the insights to optimize your communication strategy and never miss an important call again.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  Simple Process End */}



            {/* Accordion Section Start */}
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="row g-3 align-items-center">
                        <h4 className=" ff-secondary text-start w-50 fw-normal animate__animated animate__fadeInRight animate__slower 1s mb-4">
                            Frequently Asked Questions
                        </h4>
                    </div>
                    <div className="row g-4">
                        <div className="col-md-6 wow fadeInUp pe-3 pe-sm-5" data-wow-delay="0.1s">

                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={`accordion-button ${activeKey === '0' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion('0')}
                                            aria-expanded={activeKey === '0'}
                                            aria-controls="collapseOne"
                                        >
                                            What is ManageMyCalls?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className={`accordion-collapse collapse ${activeKey === '0' ? 'show' : ''}`}
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            ManageMyCalls is a text-based call management service that automatically responds to missed calls with personalized text messages, captures lead information, and sends it to you in real-time.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className={`accordion-button ${activeKey === '1' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion('1')}
                                            aria-expanded={activeKey === '1'}
                                            aria-controls="collapseTwo"
                                        >
                                            How does ManageMyCalls work?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className={`accordion-collapse collapse ${activeKey === '1' ? 'show' : ''}`}
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            When someone calls you and you're unavailable, our system instantly sends them a text message, gathers essential details, and forwards the information to you for follow-up.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className={`accordion-button ${activeKey === '2' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion('2')}
                                            aria-expanded={activeKey === '2'}
                                            aria-controls="collapseThree"
                                        >
                                            Is this service available 24/7?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className={`accordion-collapse collapse ${activeKey === '2' ? 'show' : ''}`}
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            Yes, ManageMyCalls operates 24/7, ensuring that no matter when a call comes in, it is handled promptly with a text-based response.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className={`accordion-button ${activeKey === '3' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion('3')}
                                            aria-expanded={activeKey === '3'}
                                            aria-controls="collapseFour"
                                        >
                                            How much does the service cost?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className={`accordion-collapse collapse ${activeKey === '3' ? 'show' : ''}`}
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                        We offer a simple pricing plan of $150 per month, which includes unlimited missed call handling and unlimited text responses.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className={`accordion-button ${activeKey === '4' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion('4')}
                                            aria-expanded={activeKey === '4'}
                                            aria-controls="collapseFive"
                                        >
                                            Can I customize the text responses?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className={`accordion-collapse collapse ${activeKey === '4' ? 'show' : ''}`}
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                        Yes, you can personalize the messages that are sent to missed callers, ensuring they align with your brand’s tone and style.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button
                                            className={`accordion-button ${activeKey === '5' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion('5')}
                                            aria-expanded={activeKey === '5'}
                                            aria-controls="collapseSix"
                                        >
                                            How will I receive the lead information?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSix"
                                        className={`accordion-collapse collapse ${activeKey === '5' ? 'show' : ''}`}
                                        aria-labelledby="headingSix"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                        Lead details will be sent to you in real-time via email or text, allowing you to follow up immediately.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={`accordion-button ${activeKey2 === '0' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion2('0')}
                                            aria-expanded={activeKey2 === '0'}
                                            aria-controls="collapseOne"
                                        >
                                             What kind of information is captured from missed calls?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className={`accordion-collapse collapse ${activeKey2 === '0' ? 'show' : ''}`}
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                        Our system gathers key details like the caller’s name, contact number, and any other relevant information based on the text message interaction.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className={`accordion-button ${activeKey2 === '1' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion2('1')}
                                            aria-expanded={activeKey2 === '1'}
                                            aria-controls="collapseTwo"
                                        >
                                            Can ManageMyCalls integrate with my CRM system?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className={`accordion-collapse collapse ${activeKey2 === '1' ? 'show' : ''}`}
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            Yes, ManageMyCalls can integrate with most popular CRM systems, streamlining your lead management process.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className={`accordion-button ${activeKey2 === '2' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion2('2')}
                                            aria-expanded={activeKey2 === '2'}
                                            aria-controls="collapseThree"
                                        >
                                            Is there a setup fee or any hidden costs?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className={`accordion-collapse collapse ${activeKey2 === '2' ? 'show' : ''}`}
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                        No, there are no hidden fees or setup costs. The $150/month plan covers all services.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className={`accordion-button ${activeKey2 === '3' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion2('3')}
                                            aria-expanded={activeKey2 === '3'}
                                            aria-controls="collapseFour"
                                        >
                                            What happens if I need to change my availability?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className={`accordion-collapse collapse ${activeKey2 === '3' ? 'show' : ''}`}
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                        You can easily update your schedule in the system, and ManageMyCalls will adapt to your preferred working hours.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className={`accordion-button ${activeKey2 === '4' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion2('4')}
                                            aria-expanded={activeKey2 === '4'}
                                            aria-controls="collapseFive"
                                        >
                                            Is this service only for real estate professionals?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className={`accordion-collapse collapse ${activeKey2 === '4' ? 'show' : ''}`}
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                        While designed with real estate agents in mind, ManageMyCalls can be used by any business professional who needs a reliable, text-based call management solution.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button
                                            className={`accordion-button ${activeKey2 === '5' ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => toggleAccordion2('5')}
                                            aria-expanded={activeKey2 === '5'}
                                            aria-controls="collapseSix"
                                        >
                                            How do I get started with ManageMyCalls?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSix"
                                        className={`accordion-collapse collapse ${activeKey2 === '5' ? 'show' : ''}`}
                                        aria-labelledby="headingSix"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                        Simply contact us via the form on our website, and we'll help set up your account so you can start managing your calls effortlessly.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/*  Accordion Section End */}




            <FooterPage />

        </>
    )
}

export default HomePage;