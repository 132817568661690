import React from "react";
import Navigation from "../navigation/navigation";
import FooterPage from "../navigation/footer";

const Terms=() =>{
    return (
        <>
            <div className="container-fluid position-relative p-0 ">

                <Navigation />

                <div className="container-fluid py-5 terms-header mb-5">
                    <div className="container my-5 py-5">
                        <div className="row align-items-center g-5">
                            <div className=" col-xl-7 text-center text-lg-start p-1 py-md-3">
                                <h1 className="text-white animate__animated animate__slideInLeft animate__slower 1s p-1 p-md-5">
                                    Terms & Conditions
                                </h1>

                            </div>
                            <div className="col-xl-5 ">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            


            {/* Terms Section Start */}
            <div className="container">
                <div className="row align-items-center green-bg my-3 py-3 py-md-5">
                    <h2 className="text-center text-white mb-2">Terms and Conditions</h2>
                    <p className="text-center text-white mb-3">By using ManageMyCalls, you agree to the following terms and conditions. Please read these carefully as they govern your use of our services.</p>
                    <div className="col-12 offset-md-1 col-lg-10  px-3 px-md-5 text-white my-3">
                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">1. Services Provided</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                    ManageMyCalls provides text-based call management services, including missed call responses, lead information capture, real-time notifications, and AI-powered assistance.
                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                    Our service operates 24/7 to ensure continuous call management, responding to missed calls with automated, customizable text messages.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">2. Pricing and Payment</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                    The cost for the service is $150 per month, which covers unlimited missed call handling, unlimited text responses, and 24/7 coverage.                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                    Payment is due at the beginning of each billing cycle and must be made through the provided payment methods.
                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                    We reserve the right to modify pricing, with a 30-day notice provided to existing customers.
                                </li>
                            </ul>

                        </div>

                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">3. Cancellation and Refund Policy</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                    You may cancel your subscription at any time by providing written notice. Cancellations will take effect at the end of the current billing cycle.                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                    Refunds are not provided for partial months or unused services during the billing period.                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">If we terminate your service due to a breach of these terms, no refund will be issued.</li>
                            </ul>
                        </div>

                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">4. Data Collection and Usage</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                    ManageMyCalls will collect and store lead information from missed call interactions, including contact details and inquiry details, to assist in managing your leads.
                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                    We take privacy seriously and will not share your information or the information of your callers with third parties without consent.
                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                    You are responsible for ensuring that your use of the data complies with applicable data protection regulations.
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">5. AI-Powered Services</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                Our AI system analyzes missed call interactions and provides suggested responses and lead prioritization based on caller data.
                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                While we strive for accuracy, the AI system may not be perfect in every case. ManageMyCalls is not liable for any errors in data interpretation or response timing related to AI-powered features
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">6. Customization of Services</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                You can personalize your text message templates and customize responses according to your preferences. However, you are responsible for ensuring that the content you provide is accurate and aligns with your business communication standards.                                
                                </li>
                                
                            </ul>
                        </div>

                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">7. Integration with CRM Systems</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                ManageMyCalls integrates with most popular CRM platforms. However, we are not responsible for any errors or discrepancies that may occur due to third-party system integrations.
                                </li>
                                
                            </ul>
                        </div>

                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">8. Service Availability and Technical Issues</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                While we aim to provide uninterrupted service, ManageMyCalls is not liable for outages or delays caused by third-party service providers, including but not limited to SMS gateway providers, internet outages, or technical malfunctions.
                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                In the event of scheduled maintenance, we will provide advance notice to minimize disruption.
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">9. Termination of Service</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                ManageMyCalls reserves the right to terminate or suspend your account if we detect any misuse of the service, violation of these terms, or non-payment of dues
                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                Upon termination, all collected lead information will be deleted from our system within 30 days unless legally required to retain it.
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">10. Limitations of Liability</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                ManageMyCalls is not responsible for missed opportunities, lost revenue, or any other damages caused by errors, delays, or service outages.
                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                Our liability is limited to the cost of one month’s subscription fee.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">11. Modifications to Terms</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                ManageMyCalls reserves the right to amend these terms at any time. If changes are made, we will notify customers through email or within the platform.
                                </li>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 2s">
                                While we strive for accuracy, the AI system may not be perfect in every case. ManageMyCalls is not liable for any errors in data interpretation or response timing related to AI-powered features
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">12. Governing Law</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which ManageMyCalls operates.
                                </li>
                                
                            </ul>
                        </div>

                        <div>
                            <h4 className="animate__animated animate__fadeInDown animate__slower 1s">13. Contact Information</h4>
                            <ul>
                                <li className="mb-2 animate__animated animate__fadeInLeft animate__slower 1s">
                                If you have any questions or concerns regarding these Terms and Conditions, feel free to contact us through our website or via email at support@managemycalls.ai.
                                </li>
                                
                            </ul>
                        </div>

                        


                    </div>


                </div>
            </div>
            {/* Terms Section End  */}


            <FooterPage />

        </>
    )
}

export default Terms;