import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './pages/home';
import AboutPage from './pages/about';
import ServicesPage from './pages/services';
import PricingPage from './pages/pricing';
// import Login from './login/login';
// import Register from './login/register';
// import ClientDetails from './login/clientDetails';
// import OrderDetails from './order/OrderDetails';
// import MyAccount from './login/myAccount';
import Contact from './pages/contact';
import Terms from './pages/terms';




function App() {
  return (
    <div className="container-fluid  bg-white p-0">
      {/* <HomePage /> */}
      {/* <AboutPage /> */}
      {/* <ServicesPage /> */}
      {/* <PricingPage /> */}
      {/* <Login /> */}
      {/* <Register /> */}
      {/* <ClientDetails /> */}
      {/* <OrderDetails /> */}
      {/* <MyAccount /> */}
      {/* <Contact /> */}

      <Routes>
       <Route path="/" element={<HomePage />} />
       <Route path="/about" element={<AboutPage />} />
       <Route path="/service" element={<ServicesPage />} />
       <Route path="/pricing" element={<PricingPage />} />
       {/* <Route path="/login" element={<Login />} />
       <Route path="/register" element={<Register />} />
       <Route path="/client" element={<ClientDetails />} />
       <Route path="/order" element={<OrderDetails />} />
       <Route path="/account" element={<MyAccount />} /> */}
       <Route path="/contact" element={<Contact />} />
       <Route path='/terms' element={<Terms />} />
       </Routes>

    </div>
  );
}

export default App;
