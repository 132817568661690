import React from "react";
import Navigation from "../navigation/navigation";
import FooterPage from "../navigation/footer";

import AboutImage from '../img/services.jpg';
import AiImage from '../img/services/AI.jpg';
import AssiImage from '../img/services/ASSI.jpg';
import ServiceImage from '../img/services/crm.png';
import TextImage from '../img/services/text.jpg';

import AiIconImg from '../img/icon/AI-logo.png';
import TimeIconImg from '../img/icon/24-7.png';
import AiRespIconImg from '../img/icon/ai-response.png';
import CostEffeIconImg from '../img/icon/cost-effi.png';
import LeadIconImg from '../img/icon/lead.png';
import AiSeamlesIconImg from '../img/icon/ai-seamles.png';



function ServicesPage() {
  

    return (
        <>
            <div className="container-fluid position-relative p-0 ">

                <Navigation />

                <div className="container-fluid py-5 services-header mb-5">
                    <div className="container my-5 py-5">
                        <div className="row align-items-center g-5">
                            <div className=" col-xl-7 text-center text-lg-start p-1 py-md-3">
                            <h1 className="text-white animate__animated animate__slideInLeft animate__slower p-1 p-md-5">
                                    Services
                                </h1>
                            </div>
                            <div className="col-xl-5 ">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* About Start */}
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 order-2 order-sm-1">
                        <img
                            className="img-fluid rounded w-100 animate__animated animate__zoomIn animate__slower 1s"
                            src={AboutImage} alt=''
                        />

                    </div>
                    <div className="col-lg-6 text-dark order-1 order-sm-2">
                        <h4 className="section-title ff-secondary text-start fw-normal animate__animated animate__fadeInRight animate__slower 1s">Services</h4>
                        <h3 className="mb-4 animate__animated animate__fadeInRight animate__slower 1s">The Future of Call Management with ManageMyCalls </h3>
                        <p className="mb-4 animate__animated animate__fadeInDown animate__slower 1s">
                        At ManageMyCalls, we provide a cutting-edge, 24/7 text-based call management solution designed to ensure no lead is ever missed. Our service goes beyond traditional voicemail or missed call notifications, leveraging AI to streamline communication and automate follow-ups, making it easier for professionals to focus on what matters most—building relationships and growing their business.
                        </p>

                    </div>
                </div>
            </div>
            {/* About End  */}


            
            {/* Why ManageMyCalls section Start */}
            <div className="container py-3 py-lg-5">

                <h3 className="mb-4 animate__animated animate__fadeInUp animate__slower 1s text-center">
                Comprehensive Call Management Services
                </h3>

                <div className="row g-4 text-dark align-items-center">
                    <div className="col-lg-4 col-sm-6 " >
                        <h3 className="mb-2d-block d-sm-none animate__animated animate__fadeInUp animate__slower 1s text-center">Why ManageMyCalls?</h3>
                        <div className="service-item text-center pt-3 ">
                            <div className="p-4">
                                <i className="fa fa-3x fa-user-tie text-primary mb-4"></i>
                                <h5>Automatic Text Responses to Missed Calls</h5>
                                <p>When a call is missed, instead of sending it to voicemail, our system instantly responds with a personalized text message. This keeps the conversation going, ensuring that no potential client feels ignored.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 " >
                        <h3 className="mb-2d-block d-sm-none animate__animated animate__fadeInUp animate__slower 1s text-center">Why ManageMyCalls?</h3>
                        <div className="service-item text-center pt-3 ">
                            <div className="p-4">
                                <i className="fa fa-3x fa-user-tie text-primary mb-4"></i>
                                <h5> Lead Information Capture</h5>
                                <p>Our service collects essential details such as the caller’s name, contact info, and the nature of their inquiry via text interaction. This data is then forwarded to you in real-time, making it easy for you to follow up when you’re ready.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 " >
                        <div className="service-item  pt-3 text-center">
                            <div className="p-4">
                                <i className="fa fa-3x fa-headset text-primary mb-4"></i>
                                <h5>Real-Time Notifications</h5>
                                <p>All captured information is sent to you immediately, allowing you to respond to leads faster and more efficiently. Whether you're busy with clients or away from your phone, you’ll always be in the loop.</p>
                            </div>
                        </div>                        
                    </div>

                </div>
            </div>
            {/* Why ManageMyCalls section End */}



            <div className="container">

                <h3 className="mb-4 animate__animated animate__fadeInUp animate__slower 1s text-center">
                AI-Powered Efficiency: How AI Enhances Our Services
                </h3>

                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 order-2 order-sm-1">
                        <img
                            className="img-fluid rounded w-100 animate__animated animate__zoomIn animate__slower 1s"
                            src={TextImage} alt=''
                        />

                    </div>
                    <div className="col-lg-6 text-dark order-1 order-sm-2">
                        <h3 className="mb-4 animate__animated animate__fadeInRight animate__slower 1s">Smart Text Interaction </h3>
                        <p className="mb-4 animate__animated animate__fadeInDown animate__slower 1s">
                        Our system utilizes AI-driven text communication to engage with missed callers in a way that feels personalized and professional. The AI analyzes the caller's responses and adjusts follow-up questions or messages, making the interaction feel more natural and tailored to the caller’s needs.                        </p>

                    </div>
                </div>
            </div>
            
            <div className="container my-3 my-md-5">
                <div className="row g-5 align-items-center">

                    <div className="col-lg-6 text-dark">
                        <h3 className="mb-3 animate__animated animate__fadeInLeft animate__slower 1s">AI-Based Lead Prioritization</h3>
                        <p className="mb-4 animate__animated animate__fadeInLeft animate__slower 3s">
                        The AI can evaluate incoming lead data, helping to identify high-priority clients based on their inquiry details. This ensures that you can focus on the leads most likely to convert into sales or appointments, boosting your productivity.                        </p>
                    </div>

                    <div className="col-lg-6">
                        <img
                            className="img-fluid rounded w-100 animate__animated animate__zoomIn animate__slower 1s"
                            src={AiImage} alt=""
                        />

                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 order-2 order-sm-1">
                        <img
                            className="img-fluid rounded w-100 animate__animated animate__zoomIn animate__slower 1s"
                            src={AssiImage} alt=''
                        />

                    </div>
                    <div className="col-lg-6 text-dark order-1 order-sm-2">
                        <h3 className="mb-4 animate__animated animate__fadeInRight animate__slower 1s">AI-Assisted Customization </h3>
                        <p className="mb-4 animate__animated animate__fadeInDown animate__slower 1s">
                        Our service allows you to customize your text responses. With the help of AI, the system can suggest optimized responses based on the tone of the caller’s messages or the type of inquiry. This adds a layer of personalization while saving you time.                        </p>

                    </div>
                </div>
            </div>
            
            <div className="container my-3 my-md-5">
                <div className="row g-5 align-items-center">

                    <div className="col-lg-6 text-dark">
                        <h3 className="mb-3 animate__animated animate__fadeInLeft animate__slower 1s">CRM Integration with AI Insights</h3>
                        <p className="mb-4 animate__animated animate__fadeInLeft animate__slower 3s">
                        Our AI also assists in integrating lead data with your CRM system, providing insights that help you track client interactions, follow-up timing, and even predict future client needs based on past behavior. This improves your lead nurturing and helps build stronger relationships.
                        </p>
                    </div>

                    <div className="col-lg-6">
                        <img
                            className="img-fluid rounded w-100 animate__animated animate__zoomIn animate__slower 1s"
                            src={ServiceImage} alt=''
                        />

                    </div>
                </div>
            </div>


            {/* Simple Process Start */}
            <div className="container-fluid process-bg py-5 text-white">
                <div className="container">
                    <div className="row g-3 align-items-center justify-content-center py-3">
                        <h2 className="ff-secondary text-center fw-normal animate__animated animate__fadeInRight animate__slower 1s mb-3 mb-md-5">
                        Topics That Highlight Our Value
                        </h2>
                       
                    </div>
                    <div className="row g-4 my-3">
                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    {/* <FontAwesomeIcon icon={faUser} className="fa-3x mb-4" /> */}
                                    <img
                                        className="img-fluid rounded animate__animated animate__zoomIn animate__slower 1s my-3"
                                        src={AiIconImg} alt=''
                                    />
                                    <h5>AI-Driven Call Management for Realtors</h5>
                                    <p>
                                    Real estate professionals often juggle multiple tasks at once. With AI-powered text-based call management, ManageMyCalls helps realtors stay on top of leads without missing a beat, even when they’re on the move.                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    {/* <FontAwesomeIcon icon={faFileEdit} className="fa-3x mb-4" /> */}
                                    <img
                                        className="img-fluid rounded  animate__animated animate__zoomIn animate__slower 1s my-3"
                                        src={CostEffeIconImg} alt=''
                                    />
                                    <h5>Cost-Effective and Efficient Lead Capture</h5>
                                    <p>
                                    For only $150/month, our service replaces the need for a full-time receptionist, automating responses and lead capture at a fraction of the cost. The AI ensures accuracy and efficiency, providing real-time notifications that help you follow up instantly.                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    {/* <FontAwesomeIcon icon={faThumbsUp} className="fa-3x mb-4" /> */}
                                    <img
                                        className="img-fluid rounded  animate__animated animate__zoomIn animate__slower 1s my-3"
                                        src={TimeIconImg} alt=''
                                    />
                                    <h5 className="mb-4">24/7 Availability Powered by AI</h5>
                                    <p>
                                    Thanks to AI, ManageMyCalls operates around the clock, automatically responding to calls and collecting lead data no matter the time of day. This ensures that your business is always “open,” even when you aren’t.                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 my-3">
                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    {/* <FontAwesomeIcon icon={faUser} className="fa-3x mb-4" /> */}
                                    <img
                                        className="img-fluid rounded  animate__animated animate__zoomIn animate__slower 1s my-3"
                                        src={AiRespIconImg} alt=''
                                    />
                                    <h5>Customizable AI-Enhanced Responses</h5>
                                    <p>
                                    Our AI system not only allows you to create personalized text templates but also improves them over time by analyzing client interactions. The result? Smarter responses that are always aligned with your brand's voice.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                <img
                                        className="img-fluid rounded  animate__animated animate__zoomIn animate__slower 1s my-3"
                                        src={LeadIconImg} alt=''
                                    />
                                    <h5 className="mb-4"> Lead Prioritization with<br/> AI Insights</h5>
                                    <p>
                                    By analyzing the data gathered from missed calls, our AI helps you prioritize follow-ups based on the urgency or value of the lead, allowing you to focus on the most promising opportunities.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item rounded pt-3">
                                <div className="p-4">
                                    <img
                                        className="img-fluid rounded  animate__animated animate__zoomIn animate__slower 1s my-3"
                                        src={AiSeamlesIconImg} alt=''
                                    />
                                    <h5 className="mb-4">Seamless AI Integration with Your Workflow</h5>
                                    <p>
                                    ManageMyCalls integrates smoothly with popular CRM systems, and our AI component works behind the scenes to analyze trends, predict customer needs, and help you streamline your workflow.                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  Simple Process End */}







            <FooterPage />

        </>
    )
}

export default ServicesPage;