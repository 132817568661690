import React, { useState } from "react";

import { Link } from "react-router-dom";

import Facebook from '../img/icon/facebook.png';
import Twitter from '../img/icon/twitter.png';
import Instagram from '../img/icon/instagram.png';
import LinkedIn from '../img/icon/linked-in.png';
import Youtube from '../img/icon/youtube.png';

import logo1 from '../img/logo1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faPhone, faEnvelope, faLocationDot, faAngleRight } from "@fortawesome/free-solid-svg-icons";

function FooterPage() {
    const [isNavbarSticky, setIsNavbarSticky] = useState(false);
    const [showBackToTop, setShowBackToTop] = useState(false);
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);


    const handleScroll = () => {
        const scrollTop = window.scrollY;
        setIsNavbarSticky(scrollTop > 45);
        setShowBackToTop(scrollTop > 300);
    };

    window.addEventListener("scroll", handleScroll);

    // Handle back-to-top button click
    const handleBackToTopClick = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    // Toggle navbar collapse state
    const toggleNavbar = () => {
        setIsNavbarCollapsed(!isNavbarCollapsed);
    };


    return (
        <>

            {/* Footer Start */}
            <div className="container-fluid bg-dark text-light footer pt-5  wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-12 col-lg-4 col-md-6">
                            <img src={logo1} alt="Logo" className="img-fluid mb-3 w-50" />
                            <p>
                            ManageMyCalls is a 24/7, text-based call management solution designed specifically for busy professionals, such as real estate agents. It ensures that no potential lead is missed when someone calls and you're unavailable. 
                            </p>


                            {/* <div className="d-flex pt-2">
                                <a className="btn btn-outline-light btn-social" href="">
                                    <img src={Facebook} alt="facebook Logo" />
                                </a>
                                <a className="btn btn-outline-light btn-social" href="">
                                    <img src={Twitter} alt="Twitter Logo" />
                                </a>
                                <a className="btn btn-outline-light btn-social" href="">
                                    <img src={Instagram} alt="Instagram Logo" />
                                </a>
                                <a className="btn btn-outline-light btn-social" href="">
                                    <img src={LinkedIn} alt="Linked in Logo" />
                                </a>
                                <a className="btn btn-outline-light btn-social" href="">
                                    <img src={Youtube} alt="Youtube Logo" />
                                </a>
                            </div> */}

                        </div>
                        <div className="col-12 col-lg-4 col-md-6">
                            <h4 className="section-title ff-secondary text-start fw-normal mb-4">Quick links</h4>
                            <p className="mb-2 d-flex align-items-center">
                                <FontAwesomeIcon icon={faAngleRight} className=" mx-3" />
                                <Link className="btn btn-link text-decoration-none" to="/">Home</Link>
                            </p>
                            <p className="mb-2 d-flex align-items-center">
                                <FontAwesomeIcon icon={faAngleRight} className=" mx-3" />
                                <Link className="btn btn-link text-decoration-none" to="/about">About Us</Link>
                            </p>
                            <p className="mb-2 d-flex align-items-center">
                                <FontAwesomeIcon icon={faAngleRight} className=" mx-3" />
                                <Link className="btn btn-link text-decoration-none" to="/service">Service</Link>
                            </p>
                            <p className="mb-2 d-flex align-items-center">
                                <FontAwesomeIcon icon={faAngleRight} className=" mx-3" />
                                <Link className="btn btn-link text-decoration-none" to="/pricing">Pricing</Link>
                            </p>
                            <p className="mb-2 d-flex align-items-center">
                                <FontAwesomeIcon icon={faAngleRight} className=" mx-3" />
                                <Link className="btn btn-link text-decoration-none" to="/contact">Contact</Link>
                            </p>


                        </div>
                       
                        <div className="col-12 col-lg-4 col-md-6">
                            <h4 className="section-title ff-secondary text-start fw-normal mb-4">Contact Us</h4>
                            {/* <p className="mb-2 d-flex align-items-center">
                                <FontAwesomeIcon icon={faLocationDot} className=" mx-3" />132, My Street, Kingston, New York 12401.
                            </p> */}
                            {/* <p className="mb-2 d-flex align-items-center">
                                <FontAwesomeIcon icon={faPhone} className=" mx-3" />+1 - 123 456 7890
                            </p> */}
                            <p className="mb-2 d-flex  align-items-center">
                                <a href='mailto: sales@managemycalls.ai' className="text-white text-decoration-none">
                                    <FontAwesomeIcon icon={faEnvelope} className=" mx-3" />sales@managemycalls.ai
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                Copyrights &copy; 2024 <a className="border-bottom text-decoration-none" href="https://managemycalls.ai/">ManageMyCall</a>, All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                <div className="footer-menu">
                                    <Link to="/" className="text-decoration-none">Home</Link>
                                    <Link to="/terms"  className="text-decoration-none">Terms & Conditions</Link>
                                    <Link to="/" className="text-decoration-none">Privacy Policy</Link>
                                    <Link to="/" className="text-decoration-none">FQAs</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer End */}


            {showBackToTop && (

                <a className="btn btn-lg main-btn btn-lg-square back-to-top" onClick={handleBackToTopClick} >
                    <FontAwesomeIcon icon={faArrowUp} className="text-white" />
                </a>
            )}

        </>
    )
}

export default FooterPage;