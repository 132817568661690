import React, { useState } from "react";
import Navigation from "../navigation/navigation";
import FooterPage from "../navigation/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function Contact() {
    // Form state
    const [formData, setFormData] = useState({
        manage_f_name: "",
        manage_l_name: "",
        manage_email: "",
        manage_contact_phone: "",
        manage_subject: "",
        contact_message: "",
    });

    const [statusMessage, setStatusMessage] = useState("");

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("send_email.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams(formData),
            });

            const result = await response.text();
            setStatusMessage(result);

            if (response.ok) {
                setStatusMessage("Message sent successfully!");
                setFormData({
                    manage_f_name: "",
                    manage_l_name: "",
                    manage_email: "",
                    manage_contact_phone: "",
                    manage_subject: "",
                    contact_message: "",
                });
            } else {
                setStatusMessage("Failed to send message. Please try again.");
            }
        } catch (error) {
            setStatusMessage("An error occurred. Please try again.");
            console.error("Error sending email:", error);
        }
    };

    return (
        <>
            <div className="container-fluid  position-relative p-0 ">
                <Navigation />

                <div className="container-fluid py-5 contact-header">
                    <div className="container my-3 py-3 my-md-5 py-md-5">
                        <div className="row align-items-center g-5">
                            <div className=" col-xl-7 text-center text-lg-start p-1 py-md-3">
                                <h1 className="text-white animate__animated animate__slideInLeft animate__slower 1s p-1 p-md-5">
                                    Contact Us
                                </h1>
                            </div>
                            <div className="col-xl-5 "></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Start */}
            <div className="container-fluid">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-8 p-3 p-md-5">
                            <div className="wow fadeInUp" data-wow-delay="0.1s">
                                <h1 className="mb-5 ff-secondary">Get in Touch</h1>
                            </div>

                            <div className="wow fadeInUp" data-wow-delay="0.2s">
                                <form onSubmit={handleSubmit}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-field">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="manage_f_name"
                                                    placeholder="Enter First Name"
                                                    value={formData.manage_f_name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-field">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="manage_l_name"
                                                    placeholder="Enter Last Name"
                                                    value={formData.manage_l_name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-field">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="manage_email"
                                                    placeholder="Your Email"
                                                    value={formData.manage_email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center">
                                            <div className="form-field w-100">
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    name="manage_contact_phone"
                                                    placeholder="Phone Number*"
                                                    value={formData.manage_contact_phone}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-field">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="manage_subject"
                                                    placeholder="Enter Subject *"
                                                    value={formData.manage_subject}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-field">
                                                <textarea
                                                    className="form-control"
                                                    name="contact_message"
                                                    placeholder="Enter Message*"
                                                    value={formData.contact_message}
                                                    onChange={handleChange}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3 mt-md-5">
                                            <button
                                                className="btn main-btn text-white py-1 px-5 fw-medium"
                                                type="submit"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                {statusMessage && (
                                    <p className="mt-3">{statusMessage}</p>
                                )}
                            </div>
                        </div>

                        <div className="col-md-4 wow fadeIn p-3 p-md-5 contact-details" data-wow-delay="0.1s">
                            <div className="mb-3 mb-md-5">
                                <h5 className="ff-secondary fw-normal text-start">
                                    <FontAwesomeIcon icon={faEnvelope} className="me-3" /> Write Emails
                                </h5>
                                <p><a href="mailto:sales@managemycalls.ai" class='text-decoration-none'>sales@managemycalls.ai</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact End */}

            <FooterPage />
        </>
    );
}

export default Contact;
