import React, { useState } from "react";
import { Link } from 'react-router-dom';

import logo from '../img/logo.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faBars } from "@fortawesome/free-solid-svg-icons";

function Navigation() {
    const [isNavbarSticky, setIsNavbarSticky] = useState(false);
    const [showBackToTop, setShowBackToTop] = useState(false);
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);


    const handleScroll = () => {
        const scrollTop = window.scrollY;
        setIsNavbarSticky(scrollTop > 45);
        setShowBackToTop(scrollTop > 300);
    };

    window.addEventListener("scroll", handleScroll);

    // Handle back-to-top button click
    const handleBackToTopClick = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    // Toggle navbar collapse state
    const toggleNavbar = () => {
        setIsNavbarCollapsed(!isNavbarCollapsed);
    };


    return (
        <>
            <div className="container-fluid bg-dark text-white">
                <div className="container">
                    <div className="row py-3">
                        <div className="col-12 col-md-6 col-lg-7 "></div>
                        <div className="col-4 col-md-2 col-lg-2 px-1">
                            {/* <p className="mb-0 ">
                                <FontAwesomeIcon icon={faPhone} /> +1 - 123 456 7890
                            </p> */}
                        </div>
                        <div className="col-8 col-md-4 col-lg-3 px-1 d-flex justify-content-end">
                            <a href='mailto: sales@managemycalls.ai' className="text-white text-decoration-none"><FontAwesomeIcon icon={faEnvelope} /> sales@managemycalls.ai</a> <br />
                        </div>
                    </div>
                </div>
            </div>


            <nav className={`navbar navbar-expand-lg ${isNavbarSticky ? "sticky-top shadow-sm" : ""}  px-4 px-lg-5 py-3 py-lg-2`}>
                <a href="/" className="navbar-brand p-0">
                    <img src={logo} alt="Logo" class='w-50' />
                </a>
                {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"> */}
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleNavbar}
                    aria-controls="navbarNav"
                    aria-expanded={!isNavbarCollapsed}
                    aria-label="Toggle navigation"
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <div className={`collapse navbar-collapse ${isNavbarCollapsed ? "" : "show"}`} id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0 pe-4">
                        
                        <Link to="/" className="nav-item nav-link fw-medium active mx-3 mx-md-4">Home</Link>
                        <Link to="/about" className="nav-item nav-link fw-medium mx-3 mx-md-4">About</Link>
                        <Link to="/service" className="nav-item nav-link fw-medium mx-3 mx-md-4">Service</Link>
                        <Link to="/pricing" className="nav-item nav-link fw-medium mx-3 mx-md-4">Pricing</Link>
                        <Link to="/contact" className="nav-item nav-link fw-medium mx-3 mx-md-4">Contact</Link>

                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navigation;