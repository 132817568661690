import React from "react";
import Navigation from "../navigation/navigation";
import FooterPage from "../navigation/footer";
import { Link } from 'react-router-dom';




function PricingPage() {
  

    return (
        <>
            <div className="container-fluid position-relative p-0 ">

                <Navigation />

                <div className="container-fluid py-5 pricing-header mb-5">
                    <div className="container my-5 py-5">
                        <div className="row align-items-center g-5">
                            <div className=" col-xl-7 text-center text-lg-start p-1 py-md-3">
                                <h1 className="text-white animate__animated animate__slideInLeft animate__slower 1s p-1 p-md-5">
                                    Pricing
                                </h1>

                            </div>
                            <div className="col-xl-5 ">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* About Start */}
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-12">
                        <h2 className="mb-4 text-center animate__animated animate__fadeInRight animate__slower 1s">
                            ManageMyCalls Pricing                        
                        </h2>

                    </div>
                    <div className="col-12 offset-lg-2 col-lg-8 text-dark my-3">
                        <p className="mb-4 animate__animated animate__fadeInLeft animate__slower 1s">
                            We offer a simple and transparent pricing plan designed to provide maximum value with no hidden costs or complex tiers:
                        </p>
                    </div>

                </div>
            </div>
            {/* About End  */}

            <div className="container">
                <div className="row g-5 align-items-center">

                    <div className="col-12 col-md-6  offset-lg-2 col-lg-4 text-dark my-3">
                        <div className="service-item py-3 py-md-5 orange-bg text-white">
                            <div className="">
                                <h5 className="text-center">Simple Plan</h5>
                                <h3 className="py-3 my-3 orange-bg text-white text-center">$150/month</h3>
                                <ul className="mx-4">
                                    <li>Unlimited missed call handling via text</li>
                                    <li>24/7 coverage, ensuring every call is responded to at any time</li>
                                    <li>Instant text responses to missed calls</li>
                                    <li>Real-time lead information capture and notifications</li>
                                    <li>Customizable text message templates</li>
                                    <li>AI-powered lead prioritization and follow-up suggestions</li>
                                    <li>Integration with popular CRM systems</li>
                                </ul>
                            </div>
                        </div>

                        <div className="">
                            <Link to="/contact" className="btn main-btn fw-medium text-white w-50 m-3 p-3 ">Request</Link>
                        </div>
                    </div>

                    <div className=" col-12 col-md-6  col-lg-4 text-dark my-3">
                    <div className=" rounded pt-3 border my-3">
                                    <div className="p-4">
                                        <h5>No Setup Fees</h5>
                                        <p>
                                            There are no setup fees, and you can get started immediately with our hassle-free service.
                                        </p>
                                    </div>
                                </div>
                    <div className="rounded pt-3 border my-3">
                                    <div className="p-4">
                                        <h5>No Hidden Costs</h5>
                                        <p>
                                            The $150/month covers everything—unlimited calls, texts, and AI-driven features.
                                        </p>
                                    </div>
                                </div>
                    </div>
                    <div className="col-12 offset-md-2 col-md-8 py-3">
                        <p>With our single plan, you get unlimited service at a flat rate, meaning you can handle as many missed calls as necessary without worrying about additional charges. Our AI-powered system makes sure your business stays connected to every potential lead, helping you maximize opportunities and grow your client base without the need for full-time reception staff. </p>

                       
                    </div>

                </div>
            </div>


         

            <FooterPage />

        </>
    )
}

export default PricingPage;